import React from "react"
//import { makeStyles } from "@material-ui/core"

/* const useStyles = makeStyles(theme => ({
  iframevideo: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    minHeight: "80%",
    border: 0,
    borderRadius: 1,
  },
})) */

const VideoStand = ({ color_stand, videoSrcURL, videoTitle, ...props }) => {
 // const classes = useStyles()

  return (
    <iframe
      //className={classes.iframevideo}
      style={{ background: `${color_stand}`, padding: 15, height: "100%", width: "100%"}}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  )
}

export default VideoStand
