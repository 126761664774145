import React, { useRef, useState, useContext, useEffect } from "react"
import "../css/chat.css"
import firebase from "gatsby-plugin-firebase"
//import "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { AuthContext } from "../context/auth"

/**
 *
 * cada sala de chat en mis chats que sea un listitem button como en menu con ese efecto
 * correo chat lo paso a la creacion del documento y ese es el campo de filtrado que usa el admin del chat
 * tiene un campo adicional en su documento que se guarda en el HEADER agregar a localstorage, si da undefined
 * o lo mismo que en espago de auditorio lee por uid de cliente, si da algo lee por esa otra condicion
 */

const firestore = firebase.firestore()

function ChatStands(props) {
  const { user } = useContext(AuthContext)
  const salaNombre = props.correo_chat + "-" + localStorage.getItem("uid")

  const salaRef = firestore.collection(`stands-chats`)

  //let docReferencia
  const [sala, setSala] = useState("")
  //  const [existe, setExiste] = useState(false)

  useEffect(() => {
    salaRef
      .doc(salaNombre)
      .get()
      .then(value => {
        //console.log("existe ?", value.exists)
        if (value.exists) {
          //true
          setSala(salaNombre)
         // console.log("existe")
        } else {
          //false
         // console.log("NO existe")
          salaRef
            .doc(salaNombre)
            .set({
              correo_chat: props.correo_chat,
              nombre_stand: props.nombre,
              nombre_cliente: localStorage.getItem("nombre") + " " + localStorage.getItem("apellido"),
              cliente: localStorage.getItem("uid"),
              salanombre: salaNombre,
            })
            .then(docRef => {
              //setSala(docRef.id)
              setSala(salaNombre)

            })
        }
      })

  }, [])

  return (
    <div className="ChatAuditorios">
      <section className="sectionChat" style={{ height: "97%" }}>
        {user && sala !== "" ? <ChatRoom coleccion={sala} /> : ""}
      </section>
    </div>
  )
}
// correo_chat PARA PASAR AL DOCUMENTO CUANDO SE GENERA LA SALA
function ChatRoom({ coleccion }) {
  const { user } = useContext(AuthContext)

  const dummy = useRef()
  const messagesRef = firestore.collection(`stands-chats/${coleccion}/chats`)
  const query = messagesRef.orderBy("createdAt")

  const [messages] = useCollectionData(query, { idField: "id" })

  const [formValue, setFormValue] = useState("")

  const sendMessage = async e => {
    e.preventDefault()

    const { uid, photoURL, displayName } = user

    // puedo agregar cosas del localstorge si necesito aca
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName,
    })

    setFormValue("")
    dummy.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      <main className="mainChat">
        {messages &&
          messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

        <span ref={dummy}></span>

      </main>

      <form className="formChat" onSubmit={sendMessage}>
        <input
          className="inputChat"
          value={formValue}
          onChange={e => setFormValue(e.target.value)}
          placeholder="..."
        />

        {/*  <button className="buttonChat" type="submit" disabled={!formValue}>🕊️</button> */}
      </form>
    </>
  )
}

function ChatMessage(props) {
  const { text, uid, photoURL, displayName } = props.message
  const { user } = useContext(AuthContext)

  //const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  const messageClass = uid === user.uid ? "sent" : "received"

  return (
    <>
      <div className={`message ${messageClass}`}>
        {/* <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} /> */}
        {/* <p>{displayName}</p> */}
        <p>
          {uid !== user.uid ? (
            <div>
              <span style={{ color: "blue" }}>{displayName}</span>
              <br />
            </div>
          ) : (
            ""
          )}
          {text}
        </p>
      </div>
    </>
  )
}

export default ChatStands

/* function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <>
      <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
      <p>Do not violate the community guidelines or you will be banned for life!</p>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
} */
