import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {
  Modal,
  Button,
  CssBaseline,
  //  TextField,
  Tooltip,
  Typography,
  makeStyles,
  //  Container,
} from "@material-ui/core"

import axios from "axios"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const ModalMasInformacion = ({ intl, nombreStand, boton_modal_aquiestoy }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  // envio a sheets

  const [data, setData] = useState({
    email: localStorage.getItem("email"),
    nombre_stand: nombreStand,
    nombre: localStorage.getItem("nombre"),
    apellido: localStorage.getItem("apellido"),
    fecha: Date.now().toString()
  })

/*   const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
 */
  const handleSubmit = e => {
    e.preventDefault()
    // setData({ ...data })

    /*     axios
      .post(
        "https://sheet.best/api/sheets/f8531fb8-a059-454f-9cea-ca1719380e0f",
        data
      )
      .then(response => {

        setOpen(false)
        alert('Se envio correctamente su solicitud')

      }) */
  }

  // envio a sheets END
  const currentLocale = intl.locale
  const titulo =
    currentLocale === "en"
      ? boton_modal_aquiestoy.title_en
      : currentLocale === "pt"
      ? boton_modal_aquiestoy.title_pt
      : boton_modal_aquiestoy.title
  const textoBoton =
    currentLocale === "en"
      ? boton_modal_aquiestoy.texto_en
      : currentLocale === "pt"
      ? boton_modal_aquiestoy.texto_pt
      : boton_modal_aquiestoy.texto

  const body = (
    <div className={classes.paper}>
      <CssBaseline />

      <Typography component="h6" variant="h6">
        <br />
        {intl.formatMessage({ id: "texto_pregunta_stand" })}
      </Typography>
      <form noValidate onSubmit={handleSubmit}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{
            marginTop: 20,
            background: `${boton_modal_aquiestoy.color_fondo}`,
            color: `${boton_modal_aquiestoy.color_letra}`,
          }}
        >
          {intl.formatMessage({ id: "boton_pregunta_stand" })}
        </Button>
      </form>
    </div>
  )

  return (
    <div>
      <Tooltip title={titulo} arrow placement="top">
        <Button
          fullWidth
          variant={boton_modal_aquiestoy.variante}
          style={{
            background: `${boton_modal_aquiestoy.color_fondo}`,
            color: `${boton_modal_aquiestoy.color_letra}`,
          }}
          size={boton_modal_aquiestoy.size}
          onClick={handleOpen}
        >
          {textoBoton}
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalMasInformacion)
