import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { AuthContext } from "../context/auth"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import "../css/background-image.css"
import { Button, IconButton, Hidden, Grid } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import RedesSociales from "./RedesSociales"
import VolverLogin from "../components/VolverLogin"
import VideoStand from "./VideoStand"
import ModalImagenFija from "./ModalImagenFija"
import urlSlug from "url-slug"
import ChatStands from "./ChatStands"
import imagenChat from "../images/iconos/118burbujas-de-chat-con-puntos-suspensivos.svg"
import ModalMasInformacion from "./ModalMasInformacion"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import useStands from "../hooks/useStands"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import RedesSocialesMovil from "./RedesSocialesMovil"

const Stands = ({
  intl,
  data: {
    allStrapiStands: { nodes },
    configuracion,
  },
}) => {
  const {
    video_1,
    nombre,
    logo,
    //   title,
    categoria_stand,
    imagen_fija,
    imagen_fija2,
    imagen_fija3,
    correo_chat,
    chat_activado,
    tiempo_slideshow,
    pabellon,
    redes,
    color_stand,
    color_stand_letra,
    boton_modal_aquiestoy,
    slideshow_stand,
  } = nodes[0]
  const { user } = useContext(AuthContext)
  const [chat, setChat] = useState(false) //revisar siempre q este la importacion
  const currentLocale = intl.locale

  let i
  const [ind, setInd] = useState(0)
  const cambiarImagen = () => {
    if (i < slideshow_stand.length - 1) {
      i = i + 1
      setInd(ind => ind + 1)
    } else {
      setInd(0)
      i = 0
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      cambiarImagen()
    }, tiempo_slideshow)
    return () => clearInterval(interval)
  }, [])

  const xslide = categoria_stand.slideshow_proporcion === "horizontal" ? 16 : 9
  const yslide = categoria_stand.slideshow_proporcion === "horizontal" ? 9 : 16

  const ximagenfija =
    categoria_stand.imagenfija_proporcion === "horizontal" ? 16 : 9
  const yimagenfija =
    categoria_stand.imagenfija_proporcion === "horizontal" ? 9 : 16

  const ximagenfija2 =
    categoria_stand.imagenfija2_proporcion === "horizontal" ? 16 : 9
  const yimagenfija2 =
    categoria_stand.imagenfija2_proporcion === "horizontal" ? 9 : 16

  const ximagenfija3 =
    categoria_stand.imagenfija3_proporcion === "horizontal" ? 16 : 9
  const yimagenfija3 =
    categoria_stand.imagenfija3_proporcion === "horizontal" ? 9 : 16

  //const xvideo = video_proporcion == "horizontal" ? 16 : 9
  //const yvideo = video_proporcion == "horizontal" ? 9 : 16
  //proporcion de imagen
  /*    const widthVentana = window.innerWidth
     const widthInt = parseInt(width)
     const pixelesAncho = (widthInt * widthVentana) / 100
     let altura
     // REVISAR SI ESTO VA EN UN USE EFFECT METER CONSOLE LOG ADENTRO
     if (proporcion == "vertical") {
       altura = (pixelesAncho / 9) * 16
     } else {
       altura = (pixelesAncho / 16) * 9
     } */

  /*   useEffect(() => {
    if (!user) {
      navigate("/")
    }
  }, []) */

  // ANTERIOR Y SIGUIENTE STAND
  let filtro = pabellon.nombre
  const resultadoAnteriorSiguiente = useStands((filtro = { filtro }))
  const largoArray = resultadoAnteriorSiguiente.length - 1
  const posicionStand = resultadoAnteriorSiguiente.findIndex(
    x => x.nombre === nombre
  )
  const anterior =
    posicionStand === 0
      ? resultadoAnteriorSiguiente[largoArray].nombre
      : resultadoAnteriorSiguiente[posicionStand - 1].nombre
  const siguiente =
    posicionStand === largoArray
      ? resultadoAnteriorSiguiente[0].nombre
      : resultadoAnteriorSiguiente[posicionStand + 1].nombre

  const image = getImage(categoria_stand.imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)
  return (
    <>
      {user != null ? (
        <Layout>
          <SEO title={nombre} />
          <Hidden xsDown>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              style={{ position: "fixed", top: 0 }}
              className="backimage"
            >
              {categoria_stand.nombre === "netflix" ? (
                <>
                  {categoria_stand.video_mostrar ? (
                    <div
                      style={{
                        top: categoria_stand.video_top,
                        left: categoria_stand.video_left,
                        width: categoria_stand.video_width,
                        height: categoria_stand.video_height,
                        position: "absolute",
                      }}
                    >
                      <VideoStand
                        videoSrcURL={video_1}
                        videoTitle={nombre}
                        color_stand={color_stand}
                        color_stand_letra={color_stand_letra}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <div
                      style={{
                        position: "absolute",
                        left: "0%",
                        top: "46%",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#064dae",
                          background: "#ffffff",
                          padding: 14,
                          borderRadius: 60,
                        }}
                        onClick={() => navigate("/" + urlSlug(anterior))}
                        aria-label="anterior"
                        component="span"
                      >
                        <NavigateBeforeIcon fontSize="large" />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        right: "0%",
                        top: "46%",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#064dae",
                          background: "#ffffff",
                          padding: 14,
                          borderRadius: 60,
                        }}
                        onClick={() => navigate("/" + urlSlug(siguiente))}
                        aria-label="anterior"
                        component="span"
                      >
                        <NavigateNextIcon fontSize="large" />
                      </IconButton>
                    </div>
                  </div>

                  <div
                    style={{
                      top: 0,
                      right: 0,
                      height: "15%",
                      width: "20%",
                      position: "absolute",
                      background: "#ffffff00"
                    }}
                  >
                  </div>
                  <div
                    style={{
                      bottom: 0,
                      right: 0,
                      height: "9%",
                      width: "20%",
                      position: "absolute",
                      background: "#ffffff00"
                    }}
                  >
                  </div>
                  
                  <div
                    style={{
                      position: "absolute",
                      left: `${categoria_stand.boton_volver_al_pabellon_left}`,
                      top: `${categoria_stand.boton_volver_al_pabellon_top}`,
                      width: `${categoria_stand.boton_volver_al_pabellon_width}`,
                    }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      onClick={() => navigate(`/${urlSlug(pabellon.nombre)}`)}
                      style={{
                        background: `${categoria_stand.boton_volver_al_pabellon.color_fondo}`,
                        color: `${categoria_stand.boton_volver_al_pabellon.color_letra}`,
                      }}
                    >
                      {currentLocale === "en"
                        ? categoria_stand.boton_volver_al_pabellon.en
                        : currentLocale === "pt"
                        ? categoria_stand.boton_volver_al_pabellon.pt
                        : categoria_stand.boton_volver_al_pabellon.es}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        left: "0%",
                        top: "46%",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#064dae",
                          background: "#ffffff",
                          padding: 14,
                          borderRadius: 60,
                        }}
                        onClick={() => navigate("/" + urlSlug(anterior))}
                        aria-label="anterior"
                        component="span"
                      >
                        <NavigateBeforeIcon fontSize="large" />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        right: "0%",
                        top: "46%",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#064dae",
                          background: "#ffffff",
                          padding: 14,
                          borderRadius: 60,
                        }}
                        onClick={() => navigate("/" + urlSlug(siguiente))}
                        aria-label="anterior"
                        component="span"
                      >
                        <NavigateNextIcon fontSize="large" />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        top: categoria_stand.logo_top,
                        right: categoria_stand.logo_right,
                        width: categoria_stand.logo_width,
                        height: "auto",
                        position: "absolute",
                        padding: 4,
                        borderRadius: 20,
                        background: `${color_stand}`,
                      }}
                    >
                      {logo !== null && logo.localFile !== null ? (
                        <GatsbyImage
                          image={logo.localFile.childImageSharp.gatsbyImageData}
                          alt="imagen"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        top: categoria_stand.slideshow_top,
                        left: categoria_stand.slideshow_left,
                        width: categoria_stand.slideshow_width,
                        position: "absolute",
                      }}
                    >
                      <ModalImagenFija
                        imagen={slideshow_stand[ind].imagen}
                        color_stand={color_stand}
                        color_stand_letra={color_stand_letra}
                        x={xslide}
                        y={yslide}
                        width={categoria_stand.slideshow_width}
                      />
                    </div>

                    <div
                      style={{
                        top: categoria_stand.imagenfija_top,
                        left: categoria_stand.imagenfija_left,
                        width: categoria_stand.imagenfija_width,
                        position: "absolute",
                      }}
                    >
                      <ModalImagenFija
                        imagen={imagen_fija}
                        color_stand={color_stand}
                        color_stand_letra={color_stand_letra}
                        x={ximagenfija}
                        y={yimagenfija}
                        width={categoria_stand.imagenfija_width}
                      />
                    </div>

                    {categoria_stand.imagenfija2_mostrar ? (
                      <div
                        style={{
                          top: categoria_stand.imagenfija2_top,
                          left: categoria_stand.imagenfija2_left,
                          width: categoria_stand.imagenfija2_width,
                          position: "absolute",
                        }}
                      >
                        <ModalImagenFija
                          imagen={imagen_fija2}
                          color_stand={color_stand}
                          color_stand_letra={color_stand_letra}
                          x={ximagenfija2}
                          y={yimagenfija2}
                          width={categoria_stand.imagenfija2_width}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {categoria_stand.imagenfija3_mostrar ? (
                      <div
                        style={{
                          top: categoria_stand.imagenfija3_top,
                          left: categoria_stand.imagenfija3_left,
                          width: categoria_stand.imagenfija3_width,
                          position: "absolute",
                        }}
                      >
                        <ModalImagenFija
                          imagen={imagen_fija3}
                          color_stand={color_stand}
                          color_stand_letra={color_stand_letra}
                          x={ximagenfija3}
                          y={yimagenfija3}
                          width={categoria_stand.imagenfija3_width}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {categoria_stand.video_mostrar ? (
                      <div
                        style={{
                          top: categoria_stand.video_top,
                          left: categoria_stand.video_left,
                          width: categoria_stand.video_width,
                          height: categoria_stand.video_height,
                          position: "absolute",
                        }}
                      >
                        <VideoStand
                          videoSrcURL={video_1}
                          videoTitle={nombre}
                          color_stand={color_stand}
                          color_stand_letra={color_stand_letra}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    className="redes"
                    style={{ background: `${color_stand}` }}
                  >
                    <RedesSociales redes={redes} />
                  </div>
                  {boton_modal_aquiestoy.mostrar ? (
                    <div
                      style={{
                        position: "absolute",
                        left: boton_modal_aquiestoy.left,
                        top: boton_modal_aquiestoy.top,
                      }}
                    >
                      <ModalMasInformacion
                        nombreStand={nombre}
                        boton_modal_aquiestoy={boton_modal_aquiestoy}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    style={{
                      position: "absolute",
                      left: `${categoria_stand.boton_volver_al_pabellon_left}`,
                      top: `${categoria_stand.boton_volver_al_pabellon_top}`,
                      width: `${categoria_stand.boton_volver_al_pabellon_width}`,
                    }}
                  >
                    {/*       <Link
                  to={`/${urlSlug(pabellon.nombre)}`}
                  style={{ textDecoration: "none" }}
                > */}
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      onClick={() => navigate(`/${urlSlug(pabellon.nombre)}`)}
                      style={{
                        background: `${categoria_stand.boton_volver_al_pabellon.color_fondo}`,
                        color: `${categoria_stand.boton_volver_al_pabellon.color_letra}`,
                      }}
                    >
                      {currentLocale === "en"
                        ? categoria_stand.boton_volver_al_pabellon.en
                        : currentLocale === "pt"
                        ? categoria_stand.boton_volver_al_pabellon.pt
                        : categoria_stand.boton_volver_al_pabellon.es}
                    </Button>
                    {/*   </Link> */}
                  </div>
                </>
              )}

              {chat_activado ? (
                <div
                  style={{ position: "absolute", right: "2%", bottom: "0%" }}
                >
                  <img
                    width={40}
                    onClick={() => setChat(true)}
                    src={imagenChat}
                    alt="chat"
                  />
                </div>
              ) : (
                <div></div>
              )}

              {chat_activado && chat && (
                <div
                  style={{
                    position: "absolute",
                    right: "0%",
                    top: "0%",
                    backgroundColor: "#FFFFFF99",
                    height: "90%",
                    width: "20%",
                  }}
                >
                  {/* MISMO TAMAÑO width QUE LE PASO AL TOTAL DEL CHAT en css */}
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => setChat(false)}
                  >
                    CERRAR CHAT X
                  </Button>
                  <ChatStands correo_chat={correo_chat} nombre={nombre} />
                  {/**SACAR AUDITORIO DE ACA Y LAS PROPS QUE RECIBA correo_chat */}
                </div>
              )}
            </BackgroundImage>
          </Hidden>

          {/*
INICIO DE VERSION PARA CELULAR
*/}
          <Hidden smUp>
            <Grid container direction="row" justify="center">
              {configuracion.logo_movil !== null &&
              configuracion.logo_movil.localFile !== null ? (
                <GatsbyImage
                  image={
                    configuracion.logo_movil.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt="cabecera"
                />
              ) : (
                ""
              )}
              <div>&nbsp;</div>
              <Grid item xs={12}>
                {logo !== null && logo.localFile !== null ? (
                  <GatsbyImage
                    image={logo.localFile.childImageSharp.gatsbyImageData}
                    alt="imagen"
                  />
                ) : (
                  ""
                )}
              </Grid>

              {categoria_stand.video_mostrar ? (
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <iframe
                      src={video_1}
                      title={nombre}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                <Grid container justify="center" spacing={1}>
                  <ModalImagenFija
                    imagen={slideshow_stand[ind].imagen}
                    x={xslide}
                    y={yslide}
                  />
                </Grid>
              </Grid>
              <div>&nbsp;</div>

              <Grid item xs={12}>
                <Grid container justify="center" spacing={1}>
                  <ModalImagenFija
                    imagen={imagen_fija}
                    x={ximagenfija}
                    y={yimagenfija}
                  />
                </Grid>
              </Grid>
              <div>&nbsp;</div>

              {categoria_stand.imagenfija2_mostrar ? (
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center" //spacing={1}
                  >
                    <ModalImagenFija
                      imagen={imagen_fija2}
                      x={ximagenfija2}
                      y={yimagenfija2}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <div>&nbsp;</div>

              {categoria_stand.imagenfija3_mostrar ? (
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center" //spacing={1}
                  >
                    <ModalImagenFija
                      imagen={imagen_fija3}
                      x={ximagenfija3}
                      y={yimagenfija3}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <div>&nbsp;</div>
              {boton_modal_aquiestoy.mostrar ? (
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <ModalMasInformacion
                      nombreStand={nombre}
                      boton_modal_aquiestoy={boton_modal_aquiestoy}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <div>&nbsp;</div>

              <Grid item xs={12}>
                <Grid container justify="center">
                  <RedesSocialesMovil redes={redes} />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default injectIntl(Stands)

// saque de categoria stand       descripcion_en descripcion_es descripcion_pt
export const query = graphql`
  query ($id: String!) {
    configuracion: strapiConfiguracion {
      color_movil
      logo_movil {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allStrapiStands(filter: { id: { eq: $id } }) {
      nodes {
        chat_activado
        correo_chat
        espago
        id
        nombre
        palabras_claves
        title
        tiempo_slideshow
        video_1
        color_stand
        color_stand_letra
        slideshow_stand {
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        boton_modal_aquiestoy {
          texto
          left
          top
          size
          variante
          title
          title_en
          title_pt
          texto_en
          texto_pt
          color_fondo
          color_letra
          mostrar
        }
        pabellon {
          nombre
        }
        categoria_stand {
          boton_volver_al_pabellon_width
          boton_volver_al_pabellon_top
          boton_volver_al_pabellon_left
          boton_volver_al_pabellon {
            es
            en
            pt
            color_fondo
            color_letra
          }
          nombre
          imagenfija2_left
          imagenfija2_mostrar
          imagenfija2_proporcion
          imagenfija2_top
          imagenfija2_width
          imagenfija3_left
          imagenfija3_mostrar
          imagenfija3_proporcion
          imagenfija3_top
          imagenfija3_width
          imagenfija_left
          imagenfija_proporcion
          imagenfija_top
          imagenfija_width
          logo_right
          logo_top
          logo_width
          slideshow_left
          slideshow_proporcion
          slideshow_top
          slideshow_width
          video_height
          video_left
          video_mostrar
          video_proporcion
          video_top
          video_width
          imagen_fondo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        imagen_fija {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        imagen_fija2 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        imagen_fija3 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        redes {
          audio
          carro_compras
          contenido
          correo
          descargas
          facebook
          instagram
          linkedin
          skype
          telefono
          telegram
          twitter
          web
          web_externa
          whatsapp
          youtube
          zoom
        }
      }
    }
  }
`
