import React from "react"
import { makeStyles, Modal } from "@material-ui/core"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "90%",
    minHeight: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  boton: {
    position: "absolute",
    left: "1%",
    bottom: "20%",
  },
  iframevideo: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    minHeight: "90%",
    border: 0,
    backgroundColor: "#7769927a",
    padding: 15,
    borderRadius: 1,
  },
}))

const ModalZoom = ({ zoom, icono, width }) => {
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <p id="modal-zoom">
        <iframe
          className={classes.iframevideo}
          src={zoom}
          title="Zoom"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          allow="autoplay"
        />
      </p>
      {/*      <div
      //  class="iframe-container"
      //  style="overflow: hidden; padding-top: 56.25%; position: relative;"
      >
        <iframe
          allow="microphone; camera"
          className={classes.iframevideo}
          frameborder="0"

          //style="border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%;"
          src={`https://success.zoom.us/wc/join/${zoom}`}
          //src={`https://zoom.us/wc/join/${zoom}`}
          
         sandbox="allow-same-origin allow-forms allow-scripts"
        // allowfullscreen="allowfullscreen"
        ></iframe>
      </div> */}
    </div>
  )

  return (
    <div>
      <img alt="zoom" width={width} onClick={handleOpen} src={icono} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default ModalZoom
