import React, { useState } from "react"
import { makeStyles, Modal, Button } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "32px auto",
    padding: 0,
  },

  thumb: {
    width: "100%",
  },
}))

const ModalImagenFija = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //proporcion de imagen
  const widthVentana = window.innerWidth
  const heightVentana = window.innerHeight
  const pixelesAlto = (heightVentana * 80) / 100
  const widthInt = parseInt(props.width)
  const pixelesAncho = (widthInt * widthVentana) / 100
  let altura
  let anchoModal

  const anchoPorcentajeImagen = (600 * 100) / widthVentana
  const altoParaAncho = (pixelesAlto / 16) * 9
  const anchoooo = (altoParaAncho * 100) / widthVentana

  /* useEffect(() => { */
  if (props.x === 9) {
    altura = (pixelesAncho / 9) * 16
    anchoModal = anchoooo
  } else {
    altura = (pixelesAncho / 16) * 9
    anchoModal = anchoPorcentajeImagen
  }

  const body = (
    <div className={classes.paper} style={{ width: `${anchoModal}%` }}>
      {props.imagen !== null && props.imagen.localFile !== null ? (
        <GatsbyImage
          image={props.imagen.localFile.childImageSharp.gatsbyImageData}
          alt="imagen"
        />
      ) : (
        ""
      )}
    </div>
  )

  return (
    <div>
      <Button
        type="button"
        onClick={handleOpen}
        style={{
          width: "100%",
          height: "auto",
          background: `${props.color_stand}`,
          padding: 10,
        }}
      >
        {props.imagen !== null && props.imagen.localFile !== null ? (
          <GatsbyImage
            image={props.imagen.localFile.childImageSharp.gatsbyImageData}
            alt="imagen"
            className={classes.thumb}
            style={{ height: altura }}
          />
        ) : (
          ""
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default ModalImagenFija
