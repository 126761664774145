import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Tooltip, Grid } from "@material-ui/core"
import ModalStandContenido from './ModalStandContenido'
import ModalZoom from './ModalZoom'

const RedesSocialesMovil = ({ redes }) => {
  const { iconos } = useStaticQuery(graphql`
    query{
       iconos: allFile(filter: {relativeDirectory: {eq: "iconos"}}, sort: {order: ASC, fields: name} ){
          edges{
            node{
              id
              publicURL
            }
          }
        }
      }
    `)

    const imagenesIconos = iconos.edges;
    const margen = 0;
    const width = "40px";
// agregar title sacar myBox
    const { facebook, twitter, contenido, instagram, whatsapp, youtube, linkedin, skype, telegram, telefono, descargas, web, correo, carro_compras, web_externa, audio, zoom} = redes

  return (
    <Grid container direction="row" alignItems="center">
      {contenido !== " " ? <Grid item xs={2} key="contenido"><ModalStandContenido width={width} contenido={contenido} icono={imagenesIconos[17].node.publicURL}/> </Grid> : ""} 
      {facebook !== " " ? <Grid item xs={2} key="facebook"><Tooltip title="Facebook" arrow><div style={{margin: margen}}><a href={facebook} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[1].node.publicURL} alt="Facebook"/></a></div></Tooltip> </Grid> : ""}   
      {twitter !== " " ?  <Grid item xs={2} key="twitter"><Tooltip title="Twitter" arrow><div style={{margin: margen}}><a href={twitter} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[6].node.publicURL} alt="Twitter"/></a></div></Tooltip> </Grid> : ""}
      {instagram !== " " ? <Grid item xs={2} key="instagram"> <Tooltip title="Instagram" arrow><div style={{margin: margen}}><a href={instagram} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[2].node.publicURL} alt="Instagram"/></a></div></Tooltip></Grid> : ""}    
      {whatsapp !== 0 ? <Grid item xs={2} key="whatsapp"> <Tooltip title="Whatsapp" arrow><div style={{margin: margen}}><a href={`https://api.whatsapp.com/send?phone=${whatsapp}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[7].node.publicURL} alt="Whatsapp"/></a></div></Tooltip></Grid> : ""}
      {youtube !== " " ? <Grid item xs={2} key="youtube"> <Tooltip title="Youtube" arrow><div style={{margin: margen}}><a href={youtube} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[8].node.publicURL} alt="Youtube"/></a></div></Tooltip></Grid> : ""}
      {linkedin !== " " ? <Grid item xs={2} key="linkedin"> <Tooltip title="Linkedin" arrow><div style={{margin: margen}}><a href={linkedin} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[3].node.publicURL} alt="Linkedin"/></a></div></Tooltip></Grid> : ""}
      {skype !== " " ? <Grid item xs={2} key="skype"> <Tooltip title="Skype" arrow><div style={{margin: margen}}><a href={skype} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[5].node.publicURL} alt="Skype"/></a></div></Tooltip></Grid> : ""}
      {telegram !== " " ? <Grid item xs={2} key="telegram"> <Tooltip title="Telegram" arrow><div style={{margin: margen}}><a href={`https://t.me/${telegram}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[11].node.publicURL} alt="Telegram"/></a></div></Tooltip></Grid> : ""}
      {telefono !== 0 ? <Grid item xs={2} key="telefono"> <Tooltip title={telefono} arrow><div style={{margin: margen}}><a href={`tel:${telefono}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[10].node.publicURL} alt="Telefono"/></a></div></Tooltip></Grid> : ""}
      {descargas !== " " ? <Grid item xs={2} key="descargas"><Tooltip title="Descargas" arrow><div style={{margin: margen}}><a href={descargas} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[9].node.publicURL} alt="Descargas"/></a></div></Tooltip></Grid> : ""}   
      {web !== " " ? <Grid item xs={2} key="web"><Tooltip title="Web" arrow><div style={{margin: margen}}><a href={web} rel="noreferrer" target="_blank"><img alt="web" width={width} src={imagenesIconos[12].node.publicURL}/></a></div></Tooltip></Grid> : ""}
      {correo !== " " ? <Grid item xs={2} key="correo"><Tooltip title={correo} arrow><div style={{margin: margen}}><a href={`mailto:${correo}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[0].node.publicURL} alt="Correo"/></a></div></Tooltip></Grid> : ""}
      {carro_compras !== " " ? <Grid item xs={2} key="carrodecompras"><Tooltip title="Carro de compras" arrow><div style={{margin: margen}}><a href={carro_compras} rel="noreferrer" target="_blank"><img alt="cart" width={width} src={imagenesIconos[13].node.publicURL}/></a></div></Tooltip></Grid> : ""}
      {web_externa !== " " ? <Grid item xs={2} key="webexterna"><Tooltip title="Web externa" arrow><div style={{margin: margen}}><a href={web_externa} rel="noreferrer" target="_blank"><img width={width} alt="webexterna" src={imagenesIconos[15].node.publicURL}/></a></div></Tooltip></Grid> : ""} 
      {audio !== " " ? <Grid item xs={2} key="audio"><Tooltip title="Audio" arrow><div style={{margin: margen}}><a href={audio} rel="noreferrer" target="_blank"><img width={width} alt="audio" src={imagenesIconos[16].node.publicURL}/></a></div></Tooltip></Grid> : ""}
      {zoom !== " " ? <Grid item xs={2} key="zoom"><ModalZoom width={width} zoom={zoom} icono={imagenesIconos[4].node.publicURL}/></Grid> : ""}
    </Grid>
  )
}

export default RedesSocialesMovil
