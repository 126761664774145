import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { styled, Box, Tooltip } from "@material-ui/core"
import ModalStandContenido from './ModalStandContenido'
import ModalZoom from './ModalZoom'

const MyBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flex: 1,

  })

const Iconos = ({ redes }) => {
  const { iconos } = useStaticQuery(graphql`
    query{
       iconos: allFile(filter: {relativeDirectory: {eq: "iconos"}}, sort: {order: ASC, fields: name} ){
          edges{
            node{
              id
              publicURL
            }
          }
        }
      }
    `)

    const imagenesIconos = iconos.edges;
    const margen = 0;
    const width = "40px";
// agregar title sacar myBox
    const { facebook, twitter, contenido, instagram, whatsapp, youtube, linkedin, skype, telegram, telefono, descargas, web, correo, carro_compras, web_externa, audio, zoom} = redes

  return (
    <MyBox>
      {contenido !== " " ? <ModalStandContenido width={width} contenido={contenido} icono={imagenesIconos[17].node.publicURL}/> : ""} 
      {facebook !== " " ? <Tooltip title="Facebook" arrow><div style={{margin: margen}}><a href={facebook} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[1].node.publicURL} alt="Facebook"/></a></div></Tooltip> : ""}   
      {twitter !== " " ?  <Tooltip title="Twitter" arrow><div style={{margin: margen}}><a href={twitter} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[6].node.publicURL} alt="Twitter"/></a></div></Tooltip> : ""}
      {instagram !== " " ? <Tooltip title="Instagram" arrow><div style={{margin: margen}}><a href={instagram} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[2].node.publicURL} alt="Instagram"/></a></div></Tooltip> : ""}    
      {whatsapp !== 0 ? <Tooltip title="Whatsapp" arrow><div style={{margin: margen}}><a href={`https://api.whatsapp.com/send?phone=${whatsapp}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[7].node.publicURL} alt="Whatsapp"/></a></div></Tooltip> : ""}
      {youtube !== " " ? <Tooltip title="Youtube" arrow><div style={{margin: margen}}><a href={youtube} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[8].node.publicURL} alt="Youtube"/></a></div></Tooltip> : ""}
      {linkedin !== " " ? <Tooltip title="Linkedin" arrow><div style={{margin: margen}}><a href={linkedin} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[3].node.publicURL} alt="Linkedin"/></a></div></Tooltip> : ""}
      {skype !== " " ? <Tooltip title="Skype" arrow><div style={{margin: margen}}><a href={skype} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[5].node.publicURL} alt="Skype"/></a></div></Tooltip> : ""}
      {telegram !== " " ? <Tooltip title="Telegram" arrow><div style={{margin: margen}}><a href={`https://t.me/${telegram}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[11].node.publicURL} alt="Telegram"/></a></div></Tooltip> : ""}
      {telefono !== 0 ? <Tooltip title={telefono} arrow><div style={{margin: margen}}><a href={`tel:${telefono}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[10].node.publicURL} alt="Telefono"/></a></div></Tooltip> : ""}
      {descargas !== " " ? <Tooltip title="Descargas" arrow><div style={{margin: margen}}><a href={descargas} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[9].node.publicURL} alt="Descargas"/></a></div></Tooltip> : ""}   
      {web !== " " ? <Tooltip title="Web" arrow><div style={{margin: margen}}><a href={web} rel="noreferrer" target="_blank"><img alt="web" width={width} src={imagenesIconos[12].node.publicURL}/></a></div></Tooltip> : ""}
      {correo !== " " ? <Tooltip title={correo} arrow><div style={{margin: margen}}><a href={`mailto:${correo}`} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[0].node.publicURL} alt="Correo"/></a></div></Tooltip> : ""}
      {carro_compras !== " " ? <Tooltip title="Carro de compras" arrow><div style={{margin: margen}}><a href={carro_compras} rel="noreferrer" target="_blank"><img alt="cart" width={width} src={imagenesIconos[13].node.publicURL}/></a></div></Tooltip> : ""}
      {web_externa !== " " ? <Tooltip title="Web externa" arrow><div style={{margin: margen}}><a href={web_externa} rel="noreferrer" target="_blank"><img width={width} alt="webexterna" src={imagenesIconos[15].node.publicURL}/></a></div></Tooltip> : ""} 
      {audio !== " " ? <Tooltip title="Audio" arrow><div style={{margin: margen}}><a href={audio} rel="noreferrer" target="_blank"><img width={width} alt="audio" src={imagenesIconos[16].node.publicURL}/></a></div></Tooltip> : ""}
      {/* {qr_mostrar ? <ModalImagen qr={qr} icono={imagenesIconos[14].node.publicURL}/> : ""}
     */}
       {zoom !== " " ? <ModalZoom width={width} zoom={zoom} icono={imagenesIconos[4].node.publicURL}/> : ""}
     
     {/*  {redes.zoom !== " " ? <Tooltip title="Zoom" arrow><div style={{margin: margen, marginTop: 10}}><a href={redes.zoom} rel="noreferrer" target="_blank"><img width={width} src={imagenesIconos[4].node.publicURL} alt="Zoom"/></a></div></Tooltip> : ""}
 */}

    </MyBox>
  )
}

export default Iconos
