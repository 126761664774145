import { graphql, useStaticQuery } from "gatsby"

const useStands = ({ filtro }) => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiStands(sort: { order: ASC, fields: orden }) {
          nodes {
            nombre
            id
            orden
            title
            palabras_claves
            pabellon {
              nombre
            }
            imagen_pabellon {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `
  )
  const filteredData = resultado.allStrapiStands.nodes.filter(
    node => node.pabellon.nombre === filtro
  )

  return filteredData.map(stand => ({
    //return resultado.allStrapiStands.nodes.map(stand => ({
    title: stand.title,
    nombre: stand.nombre,
    nombre_pabellon: stand.pabellon.nombre,
    imagen_pabellon: stand.imagen_pabellon,
    id: stand.id,
    orden: stand.orden,
    palabras_claves: stand.palabras_claves,
  }))
}

export default useStands
